import { useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import makeStyles from '@mui/styles/makeStyles';
import {

    Typography,
  
} from "@mui/material";
import { format, parseISO, startOfHour } from "date-fns";
import { i18n } from "../../../translate/i18n";
import useTickets from "../../../hooks/useTickets";

const useStyles = makeStyles(theme => ({
    labelLeft: {
        textAnchor: "middle",
        fill: theme.palette.text.primary,
        maxWidth: "15px",
    },
}));

const BarChartApex = () => {
    const theme = useTheme();
    const classes = useStyles();
    const date = useRef(new Date().toISOString());
    const { tickets, loading, count } = useTickets({ date: date.current, showAll: true });

    const [chartData, setChartData] = useState({
        series: [
            {
                name: "Atendimentos",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 24 horas
            },
        ],
        options: {
            chart: {
                type: "bar",
                background: "transparent",
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    columnWidth: "50%",
                },
            },
            xaxis: {
                categories: [
                    "00:00", "01:00", "02:00", "03:00", "04:00", "05:00",
                    "06:00", "07:00", "08:00", "09:00", "10:00", "11:00",
                    "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
                    "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
                ],
                labels: {
                    style: {
                        colors: theme.palette.text.secondary,
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: theme.palette.text.secondary,
                    },
                },
                title: {
                    text: i18n.t("translation.dashboard.charts.perDay.tickets"),
                    style: {
                        color: theme.palette.text.primary,
                    },
                },
            },
            fill: {
                colors: [theme.palette.primary.main],
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                borderColor: theme.palette.divider,
                strokeDashArray: 3,
            },
        },
    });

    useEffect(() => {
        if (loading || tickets.length === 0) return;

        const updatedData = [...chartData.series[0].data];

        tickets.forEach((ticket) => {
            const hour = format(startOfHour(parseISO(ticket.createdAt)), "HH:mm");
            const hourIndex = chartData.options.xaxis.categories.indexOf(hour);
            if (hourIndex >= 0) {
                updatedData[hourIndex]++;
            }
        });

        setChartData((prev) => ({
            ...prev,
            series: [{ ...prev.series[0], data: updatedData }],
        }));
    }, [tickets, loading]);

    return (
        <>
             <Typography variant="h6" component="h3" className="text-gray-700 font-bold">
             {`${i18n.t("translation.dashboard.charts.perDay.title")}${count}`}
                            </Typography>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={165}
                margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                }}
            />
        </>
    );
};

export default BarChartApex;
