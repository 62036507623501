import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Grid,
    Typography,
    useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Chart from 'react-apexcharts';
import Title from "../../../components/LayoutComponents/CardTitle";
import NPSModal from "../../../components/NPSModal";
import useTenant from "../../../hooks/useTenant";
import { i18n } from "../../../translate/i18n";

const useStyles = makeStyles(theme => ({
    alignItems: {
        padding: theme.spacing(3, 0)
    },
    avatar: {
        background: "white",
        fontSize: theme.typography.pxToRem(26),
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: "3px solid",
        marginRight: theme.spacing(2),
        transition: 'background 0.3s ease',
        "&:hover": {
            cursor: "pointer",
            transform: "scale(1.1)",
        }
    },
    avatarUnhappy: {
        borderColor: "#e57373",
        color: "#e57373",
        "&:hover": {
            background: "#f44336"
        }
    },
    avatarOk: {
        borderColor: "#ffca28",
        color: "#ffca28",
        "&:hover": {
            background: "#ffca28"
        }
    },
    avatarHappy: {
        borderColor: "#66bb6a",
        color: "#66bb6a",
        "&:hover": {
            background: "#57ca22"
        }
    },
    chartContainer: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(4)
        }
    },
    boxItem: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease',
        "&:hover": {
            background: '#f0f0f0'
        }
    }
}));

function NPSDashboard({ data, dateStart, dateEnd, queueId }) {
    const theme = useTheme();
    const classes = useStyles();

    const { tenantId } = useTenant();
    const [npsData, setNpsData] = useState([]);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState("");

    useEffect(() => {
        const controller = new AbortController();
        if (tenantId) setNpsData(data?.nps);
        return () => {
            controller.abort();
        };
    }, [data, dateStart, dateEnd, tenantId]);

    const chartOptions = {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%'
                }
            }
        },
        colors: [
            '#ba1637',
            '#f59e0b',
            '#16ba60'
        ],
        SurveyLabels: {
            enabled: true,
            formatter(val) {
                return `${val.toFixed(2)}%`;
            },
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                color: 'black',
                opacity: 0.5
            }
        },
        fill: {
            opacity: 1
        },
        labels: [
            'Ruim',
            'Ok',
            'Bom'
        ],
        legend: {
            labels: {
                colors: 'black'
            },
            show: true,
            position: 'bottom'
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        theme: {
            mode: theme.palette.mode
        }
    };

    const chartSeries = [
        parseInt(npsData?.low),
        parseInt(npsData?.ok),
        parseInt(npsData?.great)
    ];

    return (
        data && (
            <>
                <NPSModal open={open}
                    status={status}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    queueId={queueId}
                    handleClose={() => setOpen(false)}
                />

                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} className={classes.chartContainer}>
                    <Typography variant="h6" component="h3" className="text-gray-700 font-bold">
                    {i18n.t("translation.dashboard.NPS.title")}
                     </Typography>
                       
                        <Typography variant="body1" className="mb-4">
                            {i18n.t("translation.dashboard.NPS.subtitle")}
                        </Typography>
                        {(chartSeries[0] > 0 || chartSeries[1] > 0 || chartSeries[2] > 0) && (
                            <Chart
                                width="100%"
                                height={300}
                                options={chartOptions}
                                series={chartSeries}
                                type="donut"
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box className={classes.boxItem}>
                            <Avatar
                                className={[classes.avatar, classes.avatarUnhappy].join(" ")}
                                variant="rounded"
                                onClick={() => {
                                    if (npsData?.low > 0) {
                                        setOpen(true)
                                        setStatus("low")
                                    }
                                }}
                            >🙁</Avatar>
                            <Box>
                                <Typography color="error" variant="h5">
                                    {i18n.t("translation.dashboard.NPS.low.title")}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {i18n.t("translation.dashboard.NPS.low.info")}: <b>{npsData?.low}</b>
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.boxItem}>
                            <Avatar
                                className={[classes.avatar, classes.avatarOk].join(" ")}
                                variant="rounded"
                                onClick={() => {
                                    if (npsData?.ok > 0) {
                                        setOpen(true)
                                        setStatus("ok")
                                    }
                                }}
                            >😐</Avatar>
                            <Box>
                                <Typography color="warning" variant="h5">
                                    {i18n.t("translation.dashboard.NPS.ok.title")}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {i18n.t("translation.dashboard.NPS.ok.info")}: <b>{npsData?.ok}</b>
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.boxItem}>
                            <Avatar
                                className={[classes.avatar, classes.avatarHappy].join(" ")}
                                variant="rounded"
                                onClick={() => {
                                    if (npsData?.great > 0) {
                                        setOpen(true)
                                        setStatus("great")
                                    }
                                }}
                            >😀</Avatar>
                            <Box>
                                <Typography color="success" variant="h5">
                                    {i18n.t("translation.dashboard.NPS.great.title")}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {i18n.t("translation.dashboard.NPS.great.info")}: <b>{npsData?.great}</b>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </>
        )
    );
}

export default NPSDashboard;
