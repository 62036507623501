import { useEffect, useCallback } from "react";
import useSocket from "..";
import toastError from "../../../errors/toastError";

const useSocketListener = (event, callback) => {
    const { socket } = useSocket();

    // Verifica se o callback é válido, evitando recriação desnecessária
    const stableCallback = useCallback(callback, [callback]);

    useEffect(() => {
        if (!socket) {
            toastError(
                "SOCKET_ERROR_SOCKET_NOT_AVAILABLE", 
                "", 
                "Socket.IO server connection not available."
            );
            return;
        }

        if (!event || typeof stableCallback !== 'function') {
            toastError(
                "SOCKET_ERROR_EVENT_DATA_NOT_LISTENABLE", 
                "", 
                "Event or callback is invalid."
            );
            return;
        }

        // Handler para o evento
        const handleEvent = (data) => stableCallback(data);

        // Inscreve-se no evento do socket
        socket.on(event, handleEvent);

        // Cleanup do listener quando o socket, evento ou callback mudarem
        return () => {
            socket.off(event, handleEvent);
        };
    }, [socket, event, stableCallback]);
};

export default useSocketListener;
