import {
    BuildCircleOutlined,
    CheckCircleOutlined,
    CreditCardOffOutlined,
    Error,
    ErrorOutline,
    HelpOutlineOutlined,
    PauseCircleOutline,
    PhonelinkOffOutlined,
    PlayCircleOutlined,
    ScheduleOutlined,
} from "@mui/icons-material";
import { i18n } from "../../translate/i18n";

export default function StatusComponent({ status }) {
    let icon;
    let message;

    switch (status) {
        case "fail":
            icon = <Error fontSize="small" className="text-red-500" />;
            message = i18n.t("translation.variables.table.status.fail");
            break;
        case "sent":
            icon = <CheckCircleOutlined fontSize="small" className="text-green-500" />;
            message = i18n.t("translation.variables.table.status.sent");
            break;
        case "paused":
            icon = <PauseCircleOutline fontSize="small" className="text-yellow-500" />;
            message = i18n.t("translation.variables.table.status.paused");
            break;
        case "pending":
            icon = <ScheduleOutlined fontSize="small" className="text-gray-500" />;
            message = i18n.t("translation.variables.table.status.pending");
            break;
        case "preview":
            icon = <BuildCircleOutlined fontSize="small" className="text-blue-500" />;
            message = i18n.t("translation.variables.table.status.preview");
            break;
        case "processing":
            icon = <PlayCircleOutlined fontSize="small" className="text-blue-500" />;
            message = i18n.t("translation.variables.table.status.processing");
            break;
        case "error":
            icon = <ErrorOutline fontSize="small" className="text-red-500" />;
            message = i18n.t("translation.variables.table.status.error");
            break;
        case "insuficientCredits":
            icon = <CreditCardOffOutlined fontSize="small" className="text-red-500" />;
            message = i18n.t("translation.variables.table.status.insuficient_credits");
            break;
        case "whatsappUnavailable":
            icon = <PhonelinkOffOutlined fontSize="small" className="text-red-500" />;
            message = i18n.t("translation.variables.table.status.whatsappUnavailable");
            break;
        default:
            icon = <HelpOutlineOutlined fontSize="small" className="text-gray-500" />;
            message = i18n.t("translation.variables.table.status.unknown");
            break;
    }

    return (
        <div className="flex items-center space-x-2 text-sm text-gray-700">
            {icon}
            <span>{message}</span>
        </div>
    );
}
