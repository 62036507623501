import { useContext } from "react";
import SocketContext from "../../context/Socket/SocketContext";

export const useSocket = () => {
    const context = useContext(SocketContext);

    // Verifica se o contexto foi utilizado fora do provedor
    if (!context) {
        throw new Error("useSocket must be used within a SocketProvider");
    }

    return context;
}

export default useSocket;
