import React, { useState } from "react";

import {
    CheckCircleOutline,
    MoreVert,
    Replay
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { i18n } from "../../translate/i18n";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketOptionsMenu from "../TicketOptionsMenu";

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        flex: "none",
        alignSelf: "center",
        marginLeft: "auto",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    buttonMargin: {
        margin: theme.spacing(1),
    },
}));

const TicketActionButtons = ({ ticket, userTenant, handleUpdateTicketStatus, handleUpdateBlockCampaigns }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading,] = useState(false);
    const ticketOptionsMenuOpen = Boolean(anchorEl);
    const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
    const handleOpenTicketOptionsMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseTicketOptionsMenu = e => {
        setAnchorEl(null);
    };

    const handleOpenAcceptTicketWithouSelectQueue = () => {
        setAcceptTicketWithouSelectQueueOpen(true);
    };


    return (
        <div className={classes.actionButtons}>
            <AcceptTicketWithouSelectQueue
                modalOpen={acceptTicketWithouSelectQueueOpen}
                onClose={() => setAcceptTicketWithouSelectQueueOpen(false)}
                ticket={ticket}
            />
            {ticket.status === "closed" && (
                <ButtonWithSpinner
                    loading={loading}
                    startIcon={<Replay />}
                    size="small"
                    onClick={e => handleUpdateTicketStatus(e, "open", userTenant?.id)}
                >
                    {i18n.t("translation.validation.buttons.reopen")}
                </ButtonWithSpinner>
            )}
            {ticket.status === "open" && (
                <>
                    <Box display={{ xs: "inline-flex", sm: "none" }}>
                        <IconButton
                            color="primary"
                            onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                            size="large">
                            <Replay />
                        </IconButton>
                        <IconButton
                            color="primary"
                            onClick={e => handleUpdateTicketStatus(e, "closed", userTenant?.id)}
                            size="large">
                            <CheckCircleOutline />
                        </IconButton>
                        <IconButton onClick={handleOpenTicketOptionsMenu} size="large">
                            <MoreVert />
                        </IconButton>
                    </Box>

                    <Box display={{ xs: "none", sm: "inline-flex" }}>
                        <ButtonWithSpinner
                            loading={loading}
                            startIcon={<Replay />}
                            size="small"
                            className={classes.buttonMargin}
                            onClick={e => handleUpdateTicketStatus(e, "pending", null)}
                        >
                            {i18n.t("translation.validation.buttons.return")}
                        </ButtonWithSpinner>
                        <ButtonWithSpinner
                            loading={loading}
                            size="small"
                            className={classes.buttonMargin}
                            variant="contained"
                            color="primary"
                            onClick={e => handleUpdateTicketStatus(e, "closed", userTenant?.id)}
                        >
                            {i18n.t("translation.validation.buttons.resolve")}
                        </ButtonWithSpinner>
                        <IconButton onClick={handleOpenTicketOptionsMenu} size="large">
                            <MoreVert />
                        </IconButton>
                    </Box>

                    <TicketOptionsMenu
                        ticket={ticket}
                        anchorEl={anchorEl}
                        menuOpen={ticketOptionsMenuOpen}
                        handleClose={handleCloseTicketOptionsMenu}
                        handleUpdateTicketStatus={handleUpdateTicketStatus}
                        handleUpdateBlockCampaigns={handleUpdateBlockCampaigns}
                    />
                </>
            )}
            {(ticket.status === "pending" && (ticket.queue === null || ticket.queue === undefined)) && (
                <>
                <ButtonWithSpinner
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
            >
                {i18n.t("translation.validation.buttons.accept")}
                    </ButtonWithSpinner>
                    <IconButton onClick={handleOpenTicketOptionsMenu} size="large">
                            <MoreVert />
                    </IconButton>
                    <TicketOptionsMenu
                        ticket={ticket}
                        anchorEl={anchorEl}
                        menuOpen={ticketOptionsMenuOpen}
                        handleClose={handleCloseTicketOptionsMenu}
                        handleUpdateTicketStatus={handleUpdateTicketStatus}
                        handleUpdateBlockCampaigns={handleUpdateBlockCampaigns}
                    />
</>
                    )

            }
            {ticket.status === "pending" && ticket.queue !== null && (
                <>

                <ButtonWithSpinner
                    loading={loading}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={e => handleUpdateTicketStatus(e, "open", userTenant?.id)}
                >
                    {i18n.t("translation.validation.buttons.accept")}
                    </ButtonWithSpinner>
                    <IconButton onClick={handleOpenTicketOptionsMenu} size="large">
                            <MoreVert />
                    </IconButton>
                    <TicketOptionsMenu
                        ticket={ticket}
                        anchorEl={anchorEl}
                        menuOpen={ticketOptionsMenuOpen}
                        handleClose={handleCloseTicketOptionsMenu}
                        handleUpdateTicketStatus={handleUpdateTicketStatus}
                        handleUpdateBlockCampaigns={handleUpdateBlockCampaigns}
                    />
                </>
            )}
            {ticket.status === "waiting" && (
                <>
                    <ButtonWithSpinner
                        loading={loading}
                        size="small"
                        className={classes.buttonMargin}
                        variant="contained"
                        color="primary"
                        onClick={e => handleUpdateTicketStatus(e, "open", userTenant?.id)}
                    >
                        {i18n.t("translation.validation.buttons.update")}
                    </ButtonWithSpinner>
                    <ButtonWithSpinner
                        loading={loading}
                        size="small"
                        className={classes.buttonMargin}
                        variant="contained"
                        color="primary"
                        onClick={e => handleUpdateTicketStatus(e, "closed", userTenant?.id)}
                    >
                        {i18n.t("translation.validation.buttons.resolve")}
                    </ButtonWithSpinner>
                    <IconButton onClick={handleOpenTicketOptionsMenu} size="large">
                        <MoreVert />
                    </IconButton>
                    <TicketOptionsMenu
                        ticket={ticket}
                        anchorEl={anchorEl}
                        menuOpen={ticketOptionsMenuOpen}
                        handleClose={handleCloseTicketOptionsMenu}
                        handleUpdateTicketStatus={handleUpdateTicketStatus}
                        status={ticket.status}
                    />
                </>
            )}
        </div>
    );
};

export default TicketActionButtons;
