import { useEffect, useState } from "react";
import logger from "../../../services/logger";

const useSocketSetup = (socket) => {
    const [counter, setCounter] = useState(0);
    const retries = 4;
    const retryDelay = 2000; // Delay in ms between retries

    const reload = (content) => {
        logger({ type: "info", title: "Socket.io", content });
        setTimeout(() => { window.location.reload(); }, 100);
    };

    useEffect(() => {
        socket.connect();

        const handleConnection = () => {
            setCounter(0);
            logger({ type: "info", title: "Socket.IO", content: "Connected" });
        };

        const handleDisconnection = (reason, details) => {
            const description = details?.description ? `, ${details.description}` : ".";
            logger({ type: "error_info", title: "Socket.IO", content: `Disconnected: "${reason}"${description}` });
        };

        const handleConnectError = (error) => {
            if (socket.active) {
                logger({ type: "error_info", title: "Socket.io", content: "Temporary connection error. We'll automatically try to reconnect." });
            } else {
                // Token-related error handling
                if (error.message === "Socket.io: Unauthorized user.") {
                    setCounter((prev) => prev + 1);
                    logger({ type: "error_info", title: "Socket.io", content: "Invalid token." });

                    if (counter < retries) {
                        const token = localStorage.getItem("token");
                        if (socket.auth.token !== token) {
                            logger({ type: "info", title: "Socket.io", content: "Attempting reconnection with a new token." });
                            socket.auth.token = token;
                            socket.connect();
                        }
                    } else {
                        reload("Retries exhausted, reloading the page.");
                    }
                } else {
                    logger({ type: "error_info", title: "Connection Error", content: error.message });
                    reload("Unhandled socket error, reloading the page.");
                }
            }
        };

        const handleReconnectAttempt = () => {
            logger({ type: "info", title: "Socket.IO", content: "Attempting to reconnect..." });
        };

        const handleReconnectFailed = () => {
            logger({ type: "error", title: "Socket.IO", content: "Reconnection attempts failed." });
            reload("Reconnection attempts failed, reloading the page.");
        };

        // Socket events
        socket.on("connect", handleConnection);
        socket.on("disconnect", handleDisconnection);
        socket.on("connect_error", handleConnectError);
        socket.on("reconnect_attempt", handleReconnectAttempt);
        socket.on("reconnect_failed", handleReconnectFailed);

        // Cleanup on unmount
        return () => {
            ["connect", "disconnect", "connect_error", "reconnect_attempt", "reconnect_failed"].forEach((event) => {
                socket.off(event);
            });
            socket.disconnect();
        };
    }, [socket, counter]);

    return null; // Hook doesn't return any UI component
};

export default useSocketSetup;
