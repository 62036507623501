import React, { useEffect, useReducer, useRef, useState } from "react";

import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";

import {
    AccessTime,
    Block,
    Done,
    DoneAll,
    ExpandMore,
    GetApp,
} from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

import chatBackground from "../../assets/chat_background.svg";
import Audio from "../Audio";
import LocationPreview from "../LocationPreview";
import MarkdownWrapper from "../MarkdownWrapper";
import MessageOptionsMenu from "../MessageOptionsMenu";
import ModalImageCors from "../ModalImageCors";

import toastError from "../../errors/toastError";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSocketListener } from "../../hooks/useSocket/Events";
import useTenant from "../../hooks/useTenant";
import { i18n } from "../../translate/i18n";
import VcardPreview from "../VcardPreview";
import pdfIcon from '../../assets/PDF.png'

const useStyles = makeStyles((theme) => ({
    chatSender: {
        fontWeight: "bold",
        whiteSpace: "nowrap"
    },
    messagesListWrapper: {
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    messagesList: {
        backgroundColor: "#eeeae2",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "20px 20px 20px 20px",
        overflowY: "scroll",
        zIndex: 1,
        "&::before": {
            content: '""',
            backgroundImage: `url(${chatBackground})`,
            // backgroundColor: "white",
            // maskImage: `url(${chatBackground})`,
            // webkitMaskImage: `url(${chatBackground})`,
            backgroundSize: "30%",
            backgroundPosition: "center",

            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.35,
            // TODO: fix going over scroll bar
            width: "calc(100% - 8px)",
            height: "100%"
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: "90px",
        },
        ...theme.scrollbarStyles,
    },
    messageCenter: {
        marginTop: 5,
        alignItems: "center",
        verticalAlign: "center",
        alignContent: "center",
        backgroundColor: "#E1F5FEEB",
        fontSize: "12px",
        minWidth: 100,
        maxWidth: 270,
        color: "#272727",
        borderRadius: theme.spacing(1),
        borderTopLeftRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },
    messageDivider: {
        marginTop: 16
    },
    circleLoading: {
        color: theme.palette.secondary.main,
        position: "absolute",
        opacity: "70%",
        top: 0,
        left: "50%",
        marginTop: 12,
    },

    messageLeft: {
        marginRight: 20,
        marginTop: theme.spacing(0.5),
        minWidth: 100,
        maxWidth: 600,
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
        },
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#ffffff",
        color: "#303030",
        alignSelf: "flex-start",
        borderRadius: theme.spacing(1),
        borderTopLeftRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },

    quotedContainerLeft: {
        margin: "-3px -55px 6px -6px",
        overflow: "hidden",
        backgroundColor: "#f0f0f0",
        borderRadius: "7.5px",
        display: "flex",
        position: "relative",
    },

    quotedMsg: {
        padding: 10,
        maxWidth: 300,
        height: "auto",
        display: "block",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
    },

    quotedSideColorLeft: {
        flex: "none",
        width: "4px",
        backgroundColor: "#6bcbef",
    },
    messageRightType2: {
        marginLeft: 20,
        marginTop: theme.spacing(0.5),
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        fontSize: "0.9rem",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#fcffd8",
        fontFamily: "Inter",
        alignSelf: "flex-end",
        borderRadius: theme.spacing(1),
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },

    messageRight: {
        marginLeft: 20,
        marginTop: theme.spacing(0.5),
        minWidth: 100,
        maxWidth: 600,
        [theme.breakpoints.down('md')]: {
            maxWidth: "90%",
        },
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#d9fdd3",
        color: "#303030",
        alignSelf: "flex-end",
        borderRadius: theme.spacing(1),
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },
    textContentItemComment: {
        backgroundColor: theme.palette.comment.main,
        color: "rgba(0, 0, 0, 0.36)",
    },
    ticketStatus: {
        fontWeight: "bold",
        whiteSpace: "nowrap"
    },
    ticketStatusInfo: {
        whiteSpace: "nowrap"
    },
    quotedContainerRight: {
        margin: "-3px -55px 3px -6px",
        overflowY: "hidden",
        backgroundColor: "#cfe9ba",
        borderRadius: theme.spacing(1),
        display: "flex",
        position: "relative",
    },

    quotedSideColorRight: {
        flex: "none",
        width: theme.spacing(0.5),
        backgroundColor: "#35cd96",
    },

    messageActionsButton: {
        display: "none",
        position: "relative",
        color: "#999",
        zIndex: 1,
        backgroundColor: "inherit",
        opacity: "90%",
        "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
    },

    messageContactName: {
        display: "flex",
        color: "#6bcbef",
        fontWeight: 500,
    },

    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 55px 6px 6px",
    },

    textContentItemDeleted: {
        fontStyle: "italic",
        color: "rgba(0, 0, 0, 0.36)",
        overflowWrap: "break-word",
        padding: "3px 55px 6px 6px",
    },

    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    timestamp: {
        fontSize: 11,
        position: "absolute",
        marginTop: theme.spacing(0.25),
        bottom: 0,
        right: theme.spacing(0.5),
        color: "#999",
    },

    extraMarginBottom: {
        marginBottom: theme.spacing(0.5)
    },

    dailyTimestamp: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        backgroundColor: "#fff",
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1),
        boxShadow: "0 1px 1px #b3b3b3",
        zIndex: 1
    },

    dailyTimestampText: {
        color: "#808888",
        padding: 8,
        alignSelf: "center",
        marginLeft: 0,
    },

    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },

    deletedIcon: {
        fontSize: 18,
        verticalAlign: "middle",
        marginRight: 4,
    },

    ackDoneAllIcon: {
        color: green[500],
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },

    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },

    ticketNumber: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(1),
        borderRadius: theme.spacing(1),
        boxShadow: "0 1px 1px #b3b3b3",
        zIndex: 1
    },

    ticketNumberText: {
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        alignSelf: "center",
        marginLeft: 0,
    },
}));

const reducer = (state, action) => {
    if (action.type === "LOAD_MESSAGES") {
        const messages = action.payload;
        const newMessages = [];

        messages.forEach((message) => {
            const messageIndex = state.findIndex((m) => m.id === message.id);
            if (messageIndex !== -1) {
                state[messageIndex] = message;
            } else {
                newMessages.push(message);
            }
        });

        return [...newMessages, ...state];
    }

    if (action.type === "ADD_MESSAGE") {
        const newMessage = action.payload;
        const messageIndex = state.findIndex((m) => m.id === newMessage.id);

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage;
        } else {
            state.push(newMessage);
        }

        return [...state];
    }

    if (action.type === "UPDATE_MESSAGE") {
        const messageToUpdate = action.payload;
        const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

        if (messageIndex !== -1) {
            state[messageIndex] = messageToUpdate;
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const MessagesList = ({ ticketId, isGroup }) => {
    const classes = useStyles();

    const [messagesList, dispatch] = useReducer(reducer, []);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const lastMessageRef = useRef();

    const [selectedMessage, setSelectedMessage] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const messageOptionsMenuOpen = Boolean(anchorEl);
    const currentTicketId = useRef(ticketId);

    const { tenantId } = useTenant();

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);

        currentTicketId.current = ticketId;
    }, [ticketId]);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        setLoading(true);

        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                if (tenantId) {
                    try {
                        if (ticketId === undefined) return;
                        const { data } = await axiosPrivate.get(`/${tenantId}/messages/${ticketId}`, {
                            params: { pageNumber },
                        });

                        if (currentTicketId.current === ticketId && isMounted) {
                            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
                            setHasMore(data.hasMore);
                            setLoading(false);
                        }

                        if (pageNumber === 1 && data.messages.length > 1) {
                            scrollToBottom();
                        }
                    } catch (err) {
                        setLoading(false);
                        toastError(err);
                    }
                }
            };

            fetchMessages();

        }, 500);

        return () => {
            clearTimeout(delayDebounceFn);
            isMounted = false;
            controller.abort();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, ticketId, tenantId]);

    const markAsRead = async (tenantId, ticketId) => {
        try {
            await axiosPrivate.put(`/${tenantId}/tickets/${ticketId}`, { unreadMessages: 0 });
        } catch (err) {
            toastError(err, "", "Couldn't mark ticket messages as read at Use Tickets");
        }
    };

    // Handle Message Changes
    const handleMessageChange = (data) => {
        if (data.action === "create") {
            dispatch({ type: "ADD_MESSAGE", payload: data.message });

            if (!data.message.fromMe && data.ticket.id === currentTicketId.current && document.visibilityState === "visible") {
                markAsRead(data.ticket.tenantId, data.ticket.id)
            }

            scrollToBottom();
        }

        if (data.action === "update") {
            dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        }
    };
    useSocketListener(`chat:${ticketId}:message`, (data) => handleMessageChange(data))

    const loadMore = () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({});
        }
    };

    const handleScroll = (e) => {
        if (!hasMore) return;
        const { scrollTop } = e.currentTarget;

        if (scrollTop === 0) {
            document.getElementById("messagesList").scrollTop = 1;
        }

        if (loading) {
            return;
        }

        if (scrollTop < 50) {
            loadMore();
        }
    };

    const handleOpenMessageOptionsMenu = (e, message) => {
        setAnchorEl(e.currentTarget);
        setSelectedMessage(message);
    };

    const handleCloseMessageOptionsMenu = (e) => {
        setAnchorEl(null);
    };

    const checkMessageMedia = (message) => {
        if ((message.mediaType === "locationMessage" || message.mediaType === "liveLocationMessage") && message.body.split('|').length >= 2) {
            let locationParts = message.body.split('|')
            let imageLocation = locationParts[0]
            let linkLocation = locationParts[1]

            let descriptionLocation = {
                coordinates: null,
                name: null,
                address: null,
                url: null,
                type: message.mediaType
            }
            if (locationParts.length > 2) descriptionLocation.coordinates = message.body.split('|')[2]
            if (locationParts.length > 3) descriptionLocation.name = message.body.split('|')[3]
            if (locationParts.length > 4) descriptionLocation.address = message.body.split('|')[4]
            if (locationParts.length > 5) descriptionLocation.url = message.body.split('|')[5]

            return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
        } else if (message.mediaType === "contactMessage" || message.mediaType === "contactsArrayMessage") {
            let array = message.body.split("|");

            const vcardPreviews = array.map((vcard, index) => {
                const regex = {
                    name: /FN:(.*)\n/,
                    phone: /TEL;.*:(\+?\d+(?:[- ]\d+)+)/,
                    email: /EMAIL;.*:(.*)\n/,
                };

                const match = (field) => (vcard.match(regex[field]) || [])[1];

                const contactData = {
                    name: match('name'),
                    number: match('phone')?.replace(/\D/g, ""),
                    email: match('email'),
                };

                return <VcardPreview contact={contactData.name} numbers={contactData.number} key={index} />;
            });

            return vcardPreviews;
        }
        else if (message.mediaType === "image") {
            return <ModalImageCors imageUrl={message.mediaUrl} />;
        } else if (message.mediaType === "audio") {

            // I'd like to style the AUDIO HTML component with red background
            return <Audio url={message.mediaUrl} />
        } else if (message.mediaType === "video") {
            return (
                <video
                    className={classes.messageMedia}
                    src={message.mediaUrl}
                    controls
                />
            );
        } else if (message.mediaType === "doc") {
            return (
                <>
                    <div className={classes.downloadMedia}>
                        <img src={pdfIcon}  className="mr-2 h-8" alt='icon'/>

                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            target="_blank"
                            href={message.mediaUrl}
                            className="bg-slate-900 text-white hover:bg-slate-800"
                        >
                            {i18n.t("translation.validation.buttons.download")}
                        </Button>
                    </div>
                    <Divider />
                </>
            );
        } else {
            return (
                <>
                    <div className={classes.downloadMedia}>
                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            target="_blank"
                            href={message.mediaUrl}
                        >
                            {i18n.t("translation.validation.buttons.download")}
                        </Button>
                    </div>
                    <Divider />
                </>
            );
        }
    };

    const renderMessageAck = (message) => {
        if (message.ack === 0) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 1) {
            return <Done fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 2) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />;
        }
        if (message.ack === 3 || message.ack === 4) {
            return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
        }
    };

    const renderDailyTimestamps = (message, index) => {
        if (index === 0) {
            return (
                <span
                    className={classes.dailyTimestamp}
                    key={`timestamp-${message.id}`}
                >
                    <div className={classes.dailyTimestampText}>
                        {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
                    </div>
                </span>
            );
        }
        if (index < messagesList.length - 1) {
            let messageDay = parseISO(messagesList[index].createdAt);
            let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

            if (!isSameDay(messageDay, previousMessageDay)) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        key={`timestamp-${message.id}`}
                    >
                        <div className={classes.dailyTimestampText}>
                            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
                        </div>
                    </span>
                );
            }
        }
        if (index === messagesList.length - 1) {
            return (
                <div
                    key={`ref-${message.createdAt}`}
                    ref={lastMessageRef}
                    style={{ float: "left", clear: "both" }}
                />
            );
        }
    };

    const renderCallInfo = (message) => {
        return (
            <span
                className={classes.dailyTimestamp}
                key={`timestamp-${message.id}`}
            >
                <div className={classes.dailyTimestampText}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                        <path fill="#df3333" d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                    </svg> {i18n.t("translation.call.feed")} {format(parseISO(message.createdAt), "HH:mm")}
                </div>
            </span>
        );
    };

    const renderNumberTicket = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageTicket = message.ticket?.protocol;
            let previousMessageTicket = messagesList[index - 1].ticket?.protocol;

            if (messageTicket !== previousMessageTicket) {
                return (
                    <span
                        className={classes.ticketNumber}
                        key={`ticket-${message.id}`}
                    >
                        <div className={classes.ticketNumberText}>
                            <Typography variant="inherit" className={classes.ticketStatus}>
                                {i18n.t("translation.messagesList.feed.ticketSeparator")}: {messageTicket}
                            </Typography>
                            <Typography variant="inherit" className={classes.ticketStatusInfo}>
                                {i18n.t("translation.messagesList.feed.ticketConnection", { connection: message.ticket?.whatsapp?.name })} | {i18n.t("translation.messagesList.feed.ticketStart")}: {format(parseISO(message.ticket?.createdAt), "dd/MM/yyyy HH:mm")}
                            </Typography>
                        </div>
                    </span>
                );
            }
        }
    };

    const renderMessageDivider = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageUser = messagesList[index].fromMe;
            let previousMessageUser = messagesList[index - 1].fromMe;

            if (messageUser !== previousMessageUser) {
                return (
                    <span className={classes.MessageDivider} key={`divider-${message.id}`}></span>
                );
            }
        }
    };

    const renderQuotedMessage = (message) => {
        return (
            <div
                className={clsx(classes.quotedContainerLeft, {
                    [classes.quotedContainerRight]: message.fromMe,
                })}
            >
                <span
                    className={clsx(classes.quotedSideColorLeft, {
                        [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
                    })}
                ></span>
                <div className={classes.quotedMsg}>
                    {!message.quotedMsg?.fromMe && (
                        <span className={classes.messageContactName}>
                            {message.quotedMsg?.contact?.name}
                        </span>
                    )}
                    <MarkdownWrapper>{message.quotedMsg?.body}</MarkdownWrapper>
                </div>
            </div>
        );
    };

    const renderMessageSender = (message) => {
        if (!message?.sender || (!message?.isComment && !message?.isDeleted)) return;

        const content = message.body;
        const sender = message?.sender?.user?.name;
        if (content.substring(0, sender?.length + 1) === `*${sender}`) return;

        return (
            <Typography variant="inherit" className={classes.chatSender}>
                {sender}:
            </Typography>
        )
    }

    const renderEditedMessageInfo = (message) => {
        if (!message?.isEdited) return;

        return (
            <span style={{ marginRight: 2 }}>
                {i18n.t("translation.messagesList.feed.isEdited")}
            </span>
        );
    };

    const renderMessages = () => {
        if (messagesList.length > 0) {
            const viewMessagesList = messagesList.map((message, index) => {
                if (message.mediaType === "call_log") {
                    return (
                        <React.Fragment key={message.id}>
                            {renderDailyTimestamps(message, index)}
                            {renderMessageDivider(message, index)}
                            {renderNumberTicket(message, index)}
                            {renderCallInfo(message)}
                        </React.Fragment>
                    );
                }
                if (!message.fromMe) {
                    return (
                        <React.Fragment key={message.id}>
                            {renderDailyTimestamps(message, index)}
                            {renderMessageDivider(message, index)}
                            {renderNumberTicket(message, index)}
                            <div className={classes.messageLeft}>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    id="messageActionsButton"
                                    disabled={message.isDeleted}
                                    className={classes.messageActionsButton}
                                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                                >
                                    <ExpandMore />
                                </IconButton>
                                {isGroup && (
                                    <span className={classes.messageContactName}>
                                        {message.contact?.name}
                                    </span>
                                )}
                                {(
                                    message.mediaUrl ||
                                    message.mediaType === "locationMessage" ||
                                    message.mediaType === "liveLocationMessage" ||
                                    message.mediaType === "contactMessage" ||
                                    message.mediaType === "contactsArrayMessage"
                                    //|| message.mediaType === "multi_vcard"
                                ) && checkMessageMedia(message)}
                                <div className={clsx(classes.textContentItem, {
                                    [classes.textContentItemDeleted]: message.isDeleted,
                                }, {
                                    [classes.extraMarginBottom]: message.isEdited,
                                })}>
                                    {message.isDeleted && (
                                        <Block
                                            color="disabled"
                                            fontSize="small"
                                            className={classes.deletedIcon}
                                        />
                                    )}
                                    {message.quotedMsg && renderQuotedMessage(message)}
                                    {renderMessageSender(message)}
                                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                                    {message.transcription && <MarkdownWrapper>{`\n\n🤖 *${i18n.t("translation.messagesList.transcription")}:* ${message.transcription}`}</MarkdownWrapper>}

                                    <span className={classes.timestamp}>
                                        {renderEditedMessageInfo(message)}
                                        {format(parseISO(message.createdAt), "HH:mm")}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={message.id}>
                            {renderDailyTimestamps(message, index)}
                            {renderMessageDivider(message, index)}
                            {renderNumberTicket(message, index)}
                            <div className={clsx(classes.messageRight, {
                                [classes.textContentItemComment]: message.isComment
                                    || `\u200e` === message.body.charAt(0)
                                    || `\ufeff` === message.body.charAt(0),
                            })}>
                                <IconButton
                                    variant="contained"
                                    size="small"
                                    id="messageActionsButton"
                                    disabled={message.isDeleted}
                                    className={classes.messageActionsButton}
                                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                                >
                                    <ExpandMore />
                                </IconButton>
                                {(message.mediaUrl ||
                                    message.mediaType === "locationMessage" ||
                                    message.mediaType === "liveLocationMessage" ||
                                    message.mediaType === "contactMessage" ||
                                    message.mediaType === "contactsArrayMessage"
                                    //|| message.mediaType === "multi_vcard"
                                ) && checkMessageMedia(message)}
                                <div className={clsx(classes.textContentItem, {
                                    [classes.textContentItemDeleted]: message.isDeleted,
                                })} >
                                    {message.isDeleted && (
                                        <Block
                                            color="disabled"
                                            fontSize="small"
                                            className={classes.deletedIcon}
                                        />
                                    )}
                                    {message.quotedMsg && renderQuotedMessage(message)}
                                    {renderMessageSender(message)}
                                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                                    {message.transcription && <MarkdownWrapper>{`\n\n🤖 *${i18n.t("translation.messagesList.transcription")}:* ${message.transcription}`}</MarkdownWrapper>}

                                    <Tooltip
                                        arrow
                                        title={
                                            (message.deliveredAt || message.readAt) && (
                                                message.readAt
                                                    ? i18n.t("translation.contactDrawer.ack.read") + ": " + format(parseISO(message.readAt), "dd/MM/yyyy HH:mm")
                                                    : message.deliveredAt
                                                        ? i18n.t("translation.contactDrawer.ack.delivered") + ": " + format(parseISO(message.deliveredAt), "dd/MM/yyyy HH:mm")
                                                        : ""
                                            )
                                        }
                                    >
                                        <span className={classes.timestamp}>
                                            {renderEditedMessageInfo(message)}
                                            {format(parseISO(message.createdAt), "HH:mm")}
                                            {!message.isComment && renderMessageAck(message)}
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            });
            return viewMessagesList;
        } else {
            return <div>{i18n.t("translation.contactDrawer.newContact")}</div>;
        }
    };

    return (
        <div className={classes.messagesListWrapper}>
            <MessageOptionsMenu
                message={selectedMessage}
                anchorEl={anchorEl}
                menuOpen={messageOptionsMenuOpen}
                handleClose={handleCloseMessageOptionsMenu}
            />
            <div
                id="messagesList"
                className={classes.messagesList}
                onScroll={handleScroll}
            >
                {messagesList.length > 0 ? renderMessages() : []}
            </div>
            {loading && (
                <div>
                    <CircularProgress className={classes.circleLoading} />
                </div>
            )}
        </div>
    );
};

export default MessagesList;
