import toastError from "../../../errors/toastError";

// Função para emitir eventos via WebSocket
const SocketEmiter = (socket, event, data) => {
    // Verifica se o socket está disponível
    if (!socket) {
        toastError("ERR_SOCKET_NOT_AVAILABLE", "", "While trying to emit event to Socket.IO server.");
        return;
    }

    // Verifica se o evento e os dados são válidos
    if (!event || !data) {
        toastError("ERR_SOCKET_DATA_UNUSABLE", "", "Event or data is missing for Socket.IO emission.");
        return;
    }

    // Emissão do evento para o backend com garantia de tipo string no evento
    try {
        socket.emit(String(event), data, (response) => {
            console.log("Event emitted successfully:", event, response);
        });
    } catch (error) {
        // Loga o erro no console para debugging
        console.error("Error emitting socket event:", error);
        toastError("ERR_SOCKET_EMISSION_FAILED", "", `Failed to emit event: ${event}`);
    }
};

export default SocketEmiter;
