import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    CampaignOutlined,
    DeleteOutlined,
    InfoOutlined,
    KeyboardDoubleArrowDownOutlined,
    KeyboardDoubleArrowUpOutlined,
    NotificationsOff,
    PauseCircleOutlineOutlined,
    PlayCircleOutlineOutlined,
    ReplyOutlined,
    TimerOutlined
} from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";
import ConfirmationModal from "../ConfirmationModal";
import ScheduledMessagesModal from "../ScheduledMessagesModal";
import TransferTicketModal from "../TransferTicketModal";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useSettings from "../../hooks/useSettings";
import useTenant from "../../hooks/useTenant";
import Divider from "../Divider";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1)
    },
}));

const TicketOptionsMenu = ({
    ticket,
    menuOpen,
    handleClose,
    handleUpdateTicketStatus,
    handleUpdateBlockCampaigns,
    anchorEl,
    status,
}) => {
    const classes = useStyles();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [openScheduledMessageModal, setOpenScheduledMessageModal] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const isMounted = useRef(true);
    const { tenant, tenantId, userTenant } = useTenant();
    const { settings, getSettingValue } = useSettings();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();

    const [blockCampaigns, setBlockSettings] = useState(!!ticket.contact.blockCampaigns);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleCloseTicketWithoutFarewellMsg = async () => {
        if (!tenantId) return;
        try {
            await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, {
                status: "closed",
                userTenantId: userTenant?.id || null,
                sendFarewellMessage: false
            });

            navigate(`/${tenant.slug}/tickets`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleUpdateTicketPriority = async (e, priority, ticket) => {
        if (!tenantId) return;
        try {
            let data = {
                priority: priority,
            };

            await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, data);
        } catch (err) {
            toastError(err);
        }
    }

    const handleUpdateTicketAutoClosed = async (e, pauseAutoCloseTimer, ticket) => {
        if (!tenantId) return;
        try {
            let data = { pauseAutoCloseTimer: pauseAutoCloseTimer };
            await axiosPrivate.put(`/${tenantId}/tickets/${ticket.id}`, data);
        } catch (err) {
            toastError(err);
        }
    }

    const handleDeleteTicket = async () => {
        if (!tenantId) return;
        try {
            await axiosPrivate.delete(`/${tenantId}/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true);
        // handleClose();
    };

    const handleOpenTransferModal = e => {
        setTransferTicketModalOpen(true);
        // handleClose();
    };

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
        }
    };

    const handleOpenScheduledMessagesModal = () => {
        setOpenScheduledMessageModal(true);
        // handleClose();
    };
    const handleCloseScheduledMessageModal = () => {
        setOpenScheduledMessageModal(false);
    };

    return menuOpen && (<>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={menuOpen}
            onClose={handleClose}
        >
            {
                ticket?.queue?.checkAutoClose && (
                    ticket.pauseAutoCloseTimer === true
                        ? <MenuItem onClick={(e) => handleUpdateTicketAutoClosed(e, false, ticket)}>
                            <PlayCircleOutlineOutlined color="primary" size="small" className={classes.icon} />
                            {i18n.t("translation.ticketOptionsMenu.startAutoClosed")}
                        </MenuItem>
                        : <MenuItem onClick={(e) => handleUpdateTicketAutoClosed(e, true, ticket)}>
                            <PauseCircleOutlineOutlined color="primary" size="small" className={classes.icon} />
                            {i18n.t("translation.ticketOptionsMenu.pauseAutoClosed")}
                        </MenuItem>
                )
            }

            <MenuItem onClick={() => handleOpenScheduledMessagesModal()}>
                <TimerOutlined color="primary" size="small" className={classes.icon} />
                {i18n.t("translation.ticketOptionsMenu.scheduleMessage")}
            </MenuItem>
            {settings && settings.length > 0 &&
                getSettingValue("submitTicketForReview") === "enabled"
                ? status === "waiting"
                    ? null
                    : <MenuItem onClick={(e) => handleUpdateTicketStatus(e, "waiting", userTenant?.id)}>
                        <InfoOutlined color="primary" size="small" className={classes.icon} />
                        {i18n.t("translation.ticketOptionsMenu.submitForReview")}
                    </MenuItem>
                : null
            }

            <Can
                role={userTenant?.role}
                perform="ticket-options:priority"
                yes={() => (
                    ticket.priority !== '0'&& ticket.priority !== 0
                        ? <MenuItem onClick={(e) => handleUpdateTicketPriority(e, 0, ticket)}>
                            <KeyboardDoubleArrowDownOutlined color="error" size="small" className={classes.icon} />
                            {i18n.t("translation.ticketOptionsMenu.priorityDown")}
                        </MenuItem>
                        : <MenuItem onClick={(e) => handleUpdateTicketPriority(e, 1, ticket)}>
                            <KeyboardDoubleArrowUpOutlined color="primary" size="small" className={classes.icon} />
                            {i18n.t("translation.ticketOptionsMenu.priorityUp")}
                        </MenuItem>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="ticket-options:transfer"
                yes={() => (
                    <MenuItem onClick={() => handleOpenTransferModal()}>
                        <ReplyOutlined color="primary" size="small" className={classes.icon} />
                        {i18n.t("translation.ticketOptionsMenu.transfer")}
                    </MenuItem>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="ticket-options:resolveWithNoFarewell"
                yes={() => (
                    <MenuItem onClick={() => handleCloseTicketWithoutFarewellMsg()}>
                        <NotificationsOff color="primary" size="small" className={classes.icon} />
                        {i18n.t("translation.ticketOptionsMenu.resolveWithNoFarewell")}
                    </MenuItem>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="ticket-options:blockCampaigns"
                yes={() => (
                    <MenuItem onClick={(e) => {
                        // Instant update data
                        setBlockSettings((blockCampaigns) => !blockCampaigns)
                        handleUpdateBlockCampaigns(!blockCampaigns)
                    }}>
                        <CampaignOutlined
                            color={blockCampaigns ? "primary" : "error"}
                            size="small"
                            className={classes.icon}
                        />
                        <Typography
                            color={blockCampaigns ? "primary" : "error"}
                        >
                            {blockCampaigns
                                ? i18n.t("translation.ticketOptionsMenu.blockCampaigns")
                                : i18n.t("translation.ticketOptionsMenu.sendCampaigns")
                            }
                        </Typography>
                    </MenuItem>
                )}
            />
            <Can
                role={userTenant?.role}
                perform="ticket-options:delete"
                yes={() => (
                    <>
                        <Divider />
                        <MenuItem onClick={() => handleOpenConfirmationModal()}>
                            <DeleteOutlined color="error" size="small" className={classes.icon} />
                            <Typography color="error">{i18n.t("translation.ticketOptionsMenu.delete")}</Typography>
                        </MenuItem>
                    </>
                )}
            />
        </Menu>
        <ConfirmationModal
            title={`${i18n.t("translation.ticketOptionsMenu.confirmationModal.title", { ticket: ticket.id, contact: ticket.contact.name })}`}
            open={confirmationOpen}
            onClose={setConfirmationOpen}
            onConfirm={handleDeleteTicket}
        >
            {i18n.t("translation.ticketOptionsMenu.confirmationModal.message")}
        </ConfirmationModal>
        <TransferTicketModal
            modalOpen={transferTicketModalOpen}
            onClose={handleCloseTransferTicketModal}
            ticketId={ticket.id}
            ticketWhatsappId={ticket.whatsappId}
        />
        <ScheduledMessagesModal
            userTenant={userTenant}
            ticket={ticket}
            open={openScheduledMessageModal}
            onClose={handleCloseScheduledMessageModal}
        />
    </>);
};

export default TicketOptionsMenu;
